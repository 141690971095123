import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from '../../components/CaptionedImage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In the wild world of 2019 SwiftUI development, lots of things aren't documented. One such thing I ran into recently was the usage of `}<inlineCode parentName="p">{`RelativeDateTimeFormatter`}</inlineCode>{` in a `}<inlineCode parentName="p">{`Text`}</inlineCode>{` view.`}</p>
    <p><inlineCode parentName="p">{`RelativeDateTimeFormatter`}</inlineCode>{` is a new formatter which is available in iOS 13+ and macOS 10.15+. Though it's `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/foundation/relativedatetimeformatter"
      }}>{`not documented at the time of writing`}</a>{` (just like `}<a parentName="p" {...{
        "href": "https://nooverviewavailable.com/foundation/"
      }}>{`14.6% of Foundation`}</a>{`), there have been a `}<a parentName="p" {...{
        "href": "https://nshipster.com/formatter/#relativedatetimeformatter"
      }}>{`fair`}</a>{` `}<a parentName="p" {...{
        "href": "https://david.y4ng.fr/relativedatetimeformatter-and-listformatter/"
      }}>{`amount`}</a>{` of folks online writing about it. This formatter formats dates relative each other as well as relative `}<inlineCode parentName="p">{`DateComponents`}</inlineCode>{` - its output looks like "one minute ago" or "two minutes from now".`}</p>
    <p>{`Most blog posts about `}<inlineCode parentName="p">{`RelativeDateTimeFormatter`}</inlineCode>{` show its usage like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` formatter = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`RelativeDateTimeFormatter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`formatter.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`localizedString`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`DateComponents`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`day`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`-1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)) `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// "1 day ago"`}</span></span></code></pre>
    <h1 {...{
      "id": "swiftui-date-formatters-and-string-interpolation"
    }}>{`SwiftUI, date formatters, and string interpolation`}</h1>
    <p>{`SwiftUI declares a custom string interpolation (`}<a parentName="p" {...{
        "href": "https://www.hackingwithswift.com/articles/163/how-to-use-custom-string-interpolation-in-swift"
      }}>{`which is a new feature in Swift 5`}</a>{`) called `}<inlineCode parentName="p">{`LocalizedStringKey.StringInterpolation`}</inlineCode>{` (also `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/swiftui/localizedstringkey/stringinterpolation"
      }}>{`undocumented at the time of writing`}</a>{`, like `}<a parentName="p" {...{
        "href": "https://nooverviewavailable.com/swiftui/"
      }}>{`59.4% of SwiftUI`}</a>{`) which allows you to write `}<inlineCode parentName="p">{`Text`}</inlineCode>{` views with formatters like so:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"My String `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`\\(`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`myVariable, formatter: myFormatter`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`Let's assume I want to show the relative time from the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Unix_time"
      }}>{`Unix Epoch`}</a>{` until now. I'll just write a `}<inlineCode parentName="p">{`Text`}</inlineCode>{` the same way, right?`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ContentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` formatter = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`RelativeDateTimeFormatter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` body: some View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` unixEpoch = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Date`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`timeIntervalSince1970`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` components = Calendar.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`current`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`dateComponents`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            [.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`day`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`year`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`month`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`minute`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`second`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`],`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Date`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(),`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`to`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: unixEpoch`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` VStack {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Current date is:"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`\\(`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`components, formatter: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`Self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`formatter`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bold`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"since the unix Epoch"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Spacer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Unfortunately this doesn't work - the middle text doesn't show up:`}</p>
    <CaptionedImage filename="relativedatetime-1.png" alt="Screenshot of the app showing no content in the second Text view" caption="Interesting" mdxType="CaptionedImage" />
    <p>{`There's also an error in the console!`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`The supplied formatter <NSRelativeDateTimeFormatter: 0x6000025c7c00>`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`returned \`nil\` when invoked with <NSDateComponents: 0x6000026b4450> {`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    Calendar Year: -49`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    Month: -9`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    Day: -18`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    Minute: -1017`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    Second: -43. An empty string will be used instead.`}</span></code></pre>
    <h1 {...{
      "id": "the-tricky-part"
    }}>{`The Tricky Part`}</h1>
    <p>{`As far as I can tell, the reason this happens is because `}<inlineCode parentName="p">{`RelativeDateTimeFormatter`}</inlineCode>{` has a few different methods, with different signatures, for formatting its relative dates:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`localizedString`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`for`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: Date, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`relativeTo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: Date) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`localizedString`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: DateComponents) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`localizedString`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fromTimeInterval`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: TimeInterval) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`for`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Any`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span></code></pre>
    <p>{`Only one of those matches the `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/foundation/formatter"
      }}>{`Formatter`}</a>{` superclass definition that SwiftUI's string interpolation uses. I'm guessing that `}<inlineCode parentName="p">{`RelativeDateTimeFormatter`}</inlineCode>{`'s `}<inlineCode parentName="p">{`string(for:)`}</inlineCode>{` method doesn't call into the same code that `}<inlineCode parentName="p">{`localizedString(from:)`}</inlineCode>{` uses - it seems like `}<inlineCode parentName="p">{`string(for:)`}</inlineCode>{` handles the `}<inlineCode parentName="p">{`Date`}</inlineCode>{` case, but not the `}<inlineCode parentName="p">{`DateComponents`}</inlineCode>{` case.`}</p>
    <p>{`Hopefully this changes in a future version of iOS, but for now, we can solve the problem in a couple of ways: `}</p>
    <h2 {...{
      "id": "solution-1"
    }}>{`Solution #1`}</h2>
    <p>{`Since what we want is the date relative to `}<em parentName="p">{`right now`}</em>{`, and that's the default behavior of `}<inlineCode parentName="p">{`RelativeDateTimeFormatter`}</inlineCode>{`, we can just pass in `}<inlineCode parentName="p">{`epochTime`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ContentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` formatter = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`RelativeDateTimeFormatter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` body: some View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` unixEpoch = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Date`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`timeIntervalSince1970`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` VStack {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Current date is:"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`\\(`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`unixEpoch, formatter: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`Self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`formatter`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bold`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"since the unix Epoch"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Spacer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`This gets us the result we want:`}</p>
    <CaptionedImage filename="relativedatetime-2.png" alt="Screenshot of the app showing the correct relative time" caption="Note: if you want minutes, seconds, etc, set the formatter's dateTimeStyle and unitStyle" mdxType="CaptionedImage" />
    <p>{`Also note: even though nothing at the surface level is calling `}<inlineCode parentName="p">{`localizedString`}</inlineCode>{`, the string will be properly localized as long as you set the formatter's `}<inlineCode parentName="p">{`locale`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "solution-2"
    }}>{`Solution #2`}</h2>
    <p>{`We can also ignore the custom string interpolation, and just call the formatter's `}<inlineCode parentName="p">{`localizedString`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ContentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` formatter = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`RelativeDateTimeFormatter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` body: some View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` unixEpoch = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Date`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`timeIntervalSince1970`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` components = Calendar.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`current`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`dateComponents`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            [.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`day`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`year`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`month`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`minute`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`second`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`],`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Date`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(),`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`to`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: unixEpoch`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` VStack {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Current date is:"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`\\(Self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`formatter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`localizedString`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: components)`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bold`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"since the unix Epoch"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Spacer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <CaptionedImage filename="relativedatetime-2.png" alt="Screenshot of the app showing the correct relative time" caption="🎉" mdxType="CaptionedImage" />
    <h1 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h1>
    <p>{`Either of the solutions above would work for this specific issue, though if you want to output the relative time between two dates (instead of between one date and now) with the `}<inlineCode parentName="p">{`formatter:`}</inlineCode>{` string interpolation, looks like you're out of luck.`}</p>
    <p>{`Sample code for this post is available `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGSwiftUIRelativeDateTimeFormatterExample"
      }}>{`on GitHub   `}</a>{`.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      